@import "../../_variables.scss";
.learn-more--overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  // background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  .learn-more {
    &.old {
      margin-top: 80px;
      background-color: $primary;
      width: 80%;
      max-width: 600px;
      height: fit-content;
      max-height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 4;
      color: $primary-light;
      overflow-y: scroll;
      border-radius: 30px;
      padding: 12px 0px;
    }
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    color: $primary-light;
    h3 {
      font-family: $header-font;
      font-size: 34px;
      margin: 10px 0px;
      max-width: 70%;
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      width: 200px;
      margin-bottom: 15px;
      align-items: center;
    }

    .close {
      position: absolute;
      top: 27px;
      right: 30px;
      cursor: pointer;
      height: min-content;
      svg {
        display: block;
      }
      transform: scale(1.1);
      transition: transform 100ms ease-in-out;
      &:hover {
        transform: scale(1.4);
      }
    }

    &.dark {
      color: $primary !important;
      background-color: $primary-light !important;
    }

    &::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
    .learn-more-slider-container {
      width: 100%;
      margin: 15px 0px;
    }
  }
  .fill-parent {
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
}

.learn-more__description__section {
  margin: 10px 50px;
  h5 {
    font-size: 20px;
    margin: 0;
  }

  ul {
    -webkit-padding-start: 30px;
    li {
      margin-bottom: 10px;
    }
  }
}
