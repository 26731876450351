@import '../../variables';
.contact-me--container{
    width: 100%;
    height: 800px;
    background-image: url("https://www.pbs.org/wgbh/nova/media/images/carma-radio-telescope.width-2000.jpg");
    background-size: cover;
    background-position: 54% 50%;
    color: $primary-light;
    position: relative;    
    .header{
        font-family: $header-font;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 29%;
        right: 10%;
        font-size: 50px;
    }
    .subheader{
        font-family: $header-font;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 10%;
        left: 10%;
        line-height: 150%;
        font-size: 24px;
    }
    
    .contact-me-icons{
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-padding-start: 0px;
        display: flex;
        list-style-type: none;
        li{
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 900px){
    .subheader{
        max-width: 301px;
    }
}