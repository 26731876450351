@import "../../variables";

.about--container {
  width: 100%;
  background-color: $primary;
  color: $primary-dark;
  overflow-x: hidden;
  color: #151a2e;
  overflow-x: hidden;
  .sheered {
    background-color: $primary-light;
    width: 100%;
    height: 100%;
    transform: skewX(50deg) translateX(20%);
    display: flex;
    justify-content: center;
  }
  .reverse-sheered {
    transform: skewX(-50deg) translateX(-20%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 40px;
      text-transform: uppercase;
      height: min-content;
      margin: 40px auto 30px;
      padding: 0;
      font-family: $header-font;
    }

    .about__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;

      .about__content__column {
        width: 47%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        font-family: $body-font;
        font-size: 18px;
        max-width: 500px;
        display: block;
        min-width: 300px;
        line-height: 1.2;
      }
      .profile-picture--container {
        min-width: 300px;
        max-width: 300px;
        min-height: 300px;
        max-height: 300px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        border: 5px solid $primary;
        margin: 30px;

        img {
          width: 123%;
          height: auto;
          position: absolute;
          top: -47%;
          left: -4%;
          transform: rotate(3deg);
        }
      }
    }

    .test {
      height: 200px;
      width: 200px;
      overflow-y: scroll;
      p {
        font-size: 30px;
      }
      border: 1px solid black;
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 950px) {
  .about--container {
    background-color: $primary-light;
    .about__content {
      flex-direction: column;
      width: 100%;
      .about__content__column {
        width: 100% !important;

        &:first-child {
          //flex-direction: column-reverse;
        }
      }
    }
  }
}
