@import '../../_variables.scss';

.navbar--container{
    width: 100%;
    max-width: 100vw;
    height: 50px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    // justify-content: center;
    align-items: center;
    //padding: 0% 5%;
    box-sizing: border-box;
    z-index:2;
    background-color: transparent;
    transition: transform 100ms ease-in-out, background-color 500ms ease-in-out;
    &.dark{
        background-color: $primary-dark;
    }

    &.hidden{
        transform: translateY(-50px)
    }
}

.navbar__tabs{
    display: flex;
    -webkit-padding-start: 0px;
    list-style-type: none;
    li{
        opacity: 0.7;
        margin-right: 15px;
        &:hover{
            opacity: 1;
        }
        a{
            font-family: $header-font;
            font-weight: bold;
            color: white;
            text-decoration: none;
        }
    }
}