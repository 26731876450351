$header-font: 'Julius Sans One', sans-serif;

$subheader-font: 'Archivo Narrow', sans-serif;
$body-font: 'Source Sans Pro', sans-serif;

$primary: hsl(226, 38%, 24%);
$primary-dark: hsl(226, 38%, 13%);
$primary-light: hsl(226, 38%, 99%);
$primary-gray: hsl(240, 1%, 52%);

$accent: #E04531;

body{
    max-width: 100vw;
    overflow-x: hidden;
}