@import '../../variables';

.projects--container{
    background: url('https://images.unsplash.com/photo-1539717037182-c782ec2b4f28?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1647&q=80');
    background-color: black;

    background-size: 120%;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 80px 0px;
    h1{
        font-family: $header-font;
        color: $primary-light;
        margin: 0;
        margin: 0 auto;
        width: fit-content;
        font-size: 36px;
        
        margin-bottom: 80px;
    }
}

.menu{
    width: 800px;
    margin: 0 auto;
    -webkit-padding-start: 0px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    li{
        width: 22%;
        text-align: center;
        color: $primary-gray;
        cursor: pointer;
        font-size: 20px;
        &.active{
            color: $primary-light;
            text-decoration: underline;
        }
    }
}

.projects{
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

@media screen and (max-width: 1200px) {
    .projects{
        width: 600px;
    }
    
    .projects--container{
        .menu{
            width: 570px;
            li{
                width: 22%;
                text-decoration: none;
                // &.showcase{
                //     padding: 0px 20px;
                //     box-sizing: border-box;
                // }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .projects{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .projects--container{
        background: none;
        background-color: black;
        .menu{
            width: 90%;
            li{
                width: 23%;
                text-decoration: none;
                font-size: 12px;
            }
        }
    }

}
