@import "../../variables";
.landing {
  position: relative;
  font-family: "Julius Sans One", sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  background-color: $primary;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  z-index: -1;
  > img {
    height: 100%;
  }

  .fixed {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    &.small {
      filter: blur(10px);
      transition: opacity 750ms ease-in-out;
    }

    &.hidden {
      opacity: 0;
    }
  }
  .landing__intro {
    margin-top: 32vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    z-index: 0;
    pointer-events: none;
    h1 {
      font-size: 7.25vh;
      text-align: center;
      margin: 0;
    }
    h4 {
      font-size: 3.5vh;
      text-align: center;
      margin: 0;
    }

    .text {
      margin-bottom: 20vh;
    }

    .text-relflection {
      position: relative;
      opacity: 0.3;
      //idk if we should slow it at all
      transition: transform 10ms linear;
      pointer-events: none;
    }
    &.gone {
      display: none;
    }
  }

  .landing::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  // .landing {
  //   height: var(--window-innerHeight);
  //   >img{
  //       height: var(--window-innerHeight);
  //   }
  //   .landing__intro {
  //     margin-top: calc(var(--window-innerHeight)*.32);
  //     h1{
  //         font-size: calc(var(--window-innerHeight)*.1);
  //     }
  //     h4{
  //       font-size: calc(var(--window-innerHeight)*.05);
  //     }
  //     .text {
  //         margin-bottom: calc(var(--window-innerHeight)*.20);
  //       }
  //   }
  // }
}
